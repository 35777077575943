<template>
  <div class="homePage">
    <div class="left-menu">
      <img
        class="all_sys_title"
        src="@/assets/homeIcon/all_sys_title.png"
        alt=""
      >
      <div>
        <div v-for="(item, i) in menuList" :key="item.type" class="menu-list">
          <h3 class="sbu-title">
            {{ getDictName(resSysTypeList, item.type) }}
          </h3>
          <div class="menu-box">
            <div
              v-for="(cl, index) in item.list"
              :key="index"
              class="item"
              @click="getSystemMenu(cl)"
              @mouseenter="moveIn(index, '1', i)"
              @mouseleave="removeMenu(index, '1', i)"
            >
              <img
                class="icon"
                :src="
                  cl.active
                    ? require(`@/assets/homeIcon/${cl.icon}_a.png`)
                    : require(`@/assets/homeIcon/${cl.icon}.png`)
                "
                alt=""
              >
              <div>
                <p class="resName elp-1">
                  {{ cl.resName }}
                </p>
                <p class="notes elp-1">
                  {{ getResValue(cl.resName) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-info">
      <div class="corporate-box panel">
        <div class="info">
          <img src="@/assets/img/logo.png" alt="">
          {{ userInfo.orgName }}
        </div>
        <div class="user">
          <div class="add-user" @click="skip('/system/user')">
            <img src="@/assets/homeIcon/add_user.png" alt="">
            添加成员
          </div>
          <div @click="skip('/system/Role')">
            <img src="@/assets/homeIcon/role.png" alt="">
            新增角色
          </div>
        </div>
      </div>
      <div class="user-info-box panel">
        <h3 class="sbu-title">
          使用人员信息
        </h3>
        <div class="user-info">
          <div class="item">
            <span>{{ statisticsObj.userCount || 0 }}</span>
            <p>组织总人数</p>
          </div>
          <div class="item">
            <span>{{ statisticsObj.adminUserName || "admin" }}</span>
            <p>超级管理员</p>
          </div>
          <div class="item">
            <span>{{ statisticsObj.roleCount || 0 }}</span>
            <p>创建角色数</p>
          </div>
        </div>
      </div>
      <!-- 最近使用 -->
      <div class="recently-use-box panel">
        <h3 class="sbu-title">
          最近使用
        </h3>
        <div class="use-menu">
          <div
            v-for="(item, index) in latestUsageMenuList"
            :key="item.resCode"
            @click="getSystemMenu(item)"
            @mouseenter="moveIn(index, '2')"
            @mouseleave="removeMenu(index, '2')"
          >
            <img
              :src="
                item.active
                  ? require(`@/assets/homeIcon/${item.icon}_a.png`)
                  : require(`@/assets/homeIcon/${item.icon}.png`)
              "
              alt=""
            >
            {{ item.resName }}
          </div>
        </div>
      </div>
      <img
        class="shopping-mall"
        src="@/assets/homeIcon/mall.png"
        alt=""
        @click="shoppingMall"
      >
    </div>
  </div>
</template>

<script>
import { baseURL } from '@/systemConfiguration/index'
import { getAllPermission } from '@/api/systemManagement'
import { getUserAdminRoleCount } from '@/api/login'
import { getDictName } from '@/utils/util.js'
export default {
  data() {
    return {
      getDictName,
      allmenu: [],
      userInfo: {},
      allmenuArr: [],
      statisticsObj: {},
      resSysTypeList: this.$store.getters.getDictionaryItem('RES_SYS_TYPE'),
      menuList: [], // 首页菜单
      latestUsageMenuList: this.$store.state.latestUsageMenuList, // 最近使用系统
      renderJson: [
        {
          name: 'CRM客户管理系统',
          value: '营销一体化，提升协作效率和销售业绩'
        },
        { name: '资金账户系统', value: '高效管理企业的多种账户，安全高效' },
        { name: 'WMS仓储系统', value: '智能仓储管理、操作便捷、降本增效' },
        { name: 'B2B电商系统', value: '大宗商品全产业链平台，专业、可靠' },
        { name: '供应链集采系统', value: '高效的“互联网+集采”管理平台系统' },
        { name: 'TMS物流系统', value: '帮助企业实现高效的物流和运输管理' },
        { name: '仓储加工系统', value: '线上化、智能化的钢铁仓储加工管理' },
        { name: '交易数据管理', value: '实时深度数据，有效支持业务决策' },
        { name: 'MES生产系统', value: '为制造型企业提供智能生产解决方案' },
        { name: '采金链系统', value: '解决产业集群小微企业代采和集采需求' },
        { name: '生金链系统', value: '解决产业链中小微企业融资痛点' },
        { name: '赊金链系统', value: '助力卖家销售回款，实现信用变现' },
        { name: '票金链系统', value: '助力产业链持票企业的资金融通' },
        { name: '仓单融资系统', value: '提供便捷、安全的仓单质押融资服务' },
        { name: '保理融资系统', value: '支撑多类型保理业务线上化系统' },
        { name: '订单融资系统', value: '助力卖家扩大贸易、减少买资金占用' },
        { name: '票据服务系统', value: '服务小微企业实现线上票据快速融资' },
        { name: '组织架构', value: '管理和维护企业成员、角色、权限' },
        { name: '商品管理系统', value: '方便维护商品信息，便于商品管理' },
        { name: '基础设置', value: '管理和维护各系统的基础设置' },
        { name: '系统管理', value: '管理和维护系统数据字典、系统日志' },
        { name: '网站管理', value: '管理和维护官网新闻、事件、留言板' },
        { name: 'BI数据中心', value: '数据分析、数据挖掘和数据可视化' }
      ]
    }
  },
  mounted() {
    // 这个websocket的是判断有没有相同的登录的
    const baseUrl = baseURL.replace('https', 'wss').replace('http', 'ws')
    var ws = new WebSocket(
      `${baseUrl}/websocket/${localStorage.getItem('logintoken')}`,
      ['superchat']
    )
    // 获取连接状态
    ws.onmessage = () => {
      this.$alert('您的账号已经在其他设备上登录', '提示', {
        confirmButtonText: '确定',
        showClose: false,
        callback: () => {
          this.$router.push({ path: '/login' })
          // 完成通信后关闭WebSocket连接
          ws.close()
        }
      })
    }
    getAllPermission(
      (res) => {
        this.allmenuArr = [...res.data]
        if (this.allmenuArr.length > 0 && this.allmenuArr[0].children) {
          this.allmenu = [...this.allmenuArr[0].children]
        }
        const typeArr = [];
        (this.$store.getters.getDictionaryItem('RES_SYS_TYPE') || []).map(
          (item) => {
            typeArr.push({ type: item.dictId, list: [] })
          }
        )
        const menuData = [...this.allmenuArr[0].children]
        menuData.map((item) => {
          typeArr.map((cl) => {
            if (cl.type === item.resSysType) {
              cl.list.push(item)
            }
          })
        })
        this.menuList = typeArr
      },
      () => {},
      '00'
    )
    getUserAdminRoleCount({ cmpRole: '00' }, (res) => {
      this.statisticsObj = { ...res.data }
    })
    this.userInfo = { ...JSON.parse(localStorage.getItem('userdata')) }
    if (this.userInfo.orgName) {
      this.userInfo.abbreviation = this.userInfo.orgName.slice(0, 4)
    }
  },
  methods: {
    // 获取描述的方法
    getResValue(name) {
      const obj = this.renderJson.find((item) => item.name === name) || {}
      return obj.value || ''
    },
    skip(url) {
      const str = JSON.stringify(this.allmenu)
      if (str.indexOf(url) >= 0) {
        this.allmenu.forEach((item) => {
          if (item.resCode === 'institutional_framework') {
            this.$store.commit('getSystemMenu', item)
          }
        })
        this.$router.push({ path: url })
        return
      }
      this.$message.error('暂无权限')
    },
    // 点击菜单
    getSystemMenu(obj) {
      // 没有儿子的时候就只能提示哦
      if (obj.children && obj.children.length === 0) {
        this.$message.error('正在建设中...')
        return
      }
      if (obj === 2 && this.allmenuArr.length > 0) {
        this.$store.commit('getSystemMenu', this.allmenuArr[0])
        this.$router.push({ path: '/' })
        return
      }
      if (obj.children && obj.children.length > 0) {
        this.$store.commit('getSystemMenu', obj)
        const params = JSON.parse(JSON.stringify(obj))
        delete params.active
        this.$store.commit('setLatestUsageMenuList', params)
        if (obj.children[0].resUrl) {
          this.$router.push({ path: obj.children[0].resUrl })
          return
        }
        if (obj.children[0].children && obj.children[0].children.length > 0) {
          this.$router.push({ path: obj.children[0].children[0].resUrl })
        }
        return
      }
    },
    moveIn(index, val, i) {
      if (val === '1') {
        return this.$set(this.menuList[i].list[index], 'active', true)
      }
      this.$set(this.latestUsageMenuList[index], 'active', true)
    },
    removeMenu(index, val, i) {
      if (val === '1') {
        return this.$set(this.menuList[i].list[index], 'active', false)
      }
      this.$set(this.latestUsageMenuList[index], 'active', false)
    },
    shoppingMall() {
      window.open(`${this.$projectUrl}login`, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.homePage {
  padding: 20px;
  height: calc(100% - 40px);
  background: url("../../assets/img/main_bg.png");
  display: flex;
  justify-content: space-between;
}

.left-menu {
  background-color: #fff;
  flex: 1;
  margin-right: 16px;
  padding: 20px 28px;
  overflow-y: auto;
  .all_sys_title {
    width: 80px;
    height: 30px;
    margin-bottom: 32px;
  }
  .menu-list {
    margin-bottom: 16px;
    .sbu-title {
      margin-bottom: 16px;
    }
  }
  .menu-box {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 25%;
      // width: 312px;
      height: 78px;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding-left: 16px;
      box-sizing: border-box;
      .icon {
        width: 48px;
        height: 48px;
        margin-right: 12px;
      }
      .resName {
        color: #333;
        font-size: 15px;
        margin-bottom: 6px;
      }
      .notes {
        color: #666;
        font-size: 13px;
      }
      @media screen and (min-width: 1200px) and (max-width: 1600px) {
        .resName {
          font-size: 14px;
        }
        .notes {
          font-size: 12px;
        }
        .icon {
          width: 36px;
          height: 36px;
          margin-right: 12px;
        }
      }
      @media screen and (min-width: 400px) and (max-width: 1200px) {
        .resName {
          font-size: 10px;
        }
        .icon {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
        .notes {
          font-size: 10px;
        }
      }
      &:hover {
        background-color: #f9f9f9;
      }
    }
  }
}

.right-info {
  flex: 0 0 512px;

  .panel {
    padding: 24px;
    background-color: #fff;
    margin-bottom: 16px;
  }

  .corporate-box {
    .info {
      display: flex;
      align-items: center;
      color: #333333ff;
      font-size: 18px;
      img {
        height: 56px;
        margin-right: 8px;
      }
    }

    .user {
      display: flex;
      margin-left: 64px;
      margin-top: 20px;
      .add-user {
        margin-right: 16px;
      }
      > div {
        background-color: #f9f9f9;
        color: #333333ff;
        font-size: 14px;
        width: 192px;
        line-height: 38px;
        text-align: center;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
          vertical-align: middle;
        }
      }
    }
  }

  .user-info-box {
    padding-bottom: 30px;
    .user-info {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      .item {
        text-align: center;
        span {
          color: #333333ff;
          font-size: 24px;
        }
        p {
          color: #666666ff;
          font-size: 14px;
        }
      }
    }
  }

  .recently-use-box {
    .use-menu {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 24px;
      > div {
        width: 224px;
        height: 40px;
        line-height: 40px;
        background: #f4f4f4ff;
        color: #333333ff;
        font-size: 14px;
        margin-bottom: 16px;
        padding-left: 18px;
        box-sizing: border-box;
        cursor: pointer;
        img {
          width: 18px;
          height: 18px;
          vertical-align: middle;
        }
      }
    }
  }

  .shopping-mall {
    width: 512px;
    height: 225px;
    cursor: pointer;
  }
}
.sbu-title {
  color: #000000ff;
  font-size: 16px;
  font-weight: 500;
}
</style>
